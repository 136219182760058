<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>{{ $route.name }}</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-col :md="24">
              <el-row :gutter="4" type="flex" justify="end" align="center">
                <el-button
                  type="primary"
                  icon="el-icon-s-operation"
                  class="mb-0"
                  size="medium"
                  @click="openFilterAnimalsModal()"
                ></el-button>
                <el-button
                  type="primary"
                  class="mb-0"
                  icon="el-icon-download"
                  :loading="isLoadingDownloadButton"
                  size="medium"
                  @click="downloadReport()"
                ></el-button>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Animals?.data"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="code" label="Nº"> </el-table-column>
      <el-table-column
        label="data"
        :formatter="(r) => formatDate(r.created_at)"
      >
      </el-table-column>

      <el-table-column label="produtor" prop="provider.name"> </el-table-column>
      <el-table-column
        label="carcaças"
        prop="carcasses.length"
      ></el-table-column>
      <el-table-column
        label="preço por kg"
        :formatter="(r) => currencyFormatter.format(Number(r.price) || 0)"
      ></el-table-column>
      <el-table-column label="peso aferido">
        <template #default="p">
          {{
            `${numberFormatter.format(
              p?.row?.carcasses?.reduce(
                (t, e) => (t += Number(e?.weight || 0)),
                0
              ) || 0
            )}`
          }}
          kg.
        </template>
      </el-table-column>
      <el-table-column label="peso declarado">
        <template #default="p">
          {{
            `${numberFormatter.format(
              (Number(p.row.weight) || 0) + (Number(p.row.weight_) || 0)
            )}`
          }}
          kg.
        </template>
      </el-table-column>
      <el-table-column label="preço total">
        <template #default="p">
          {{
            `${currencyFormatter.format(
              ((Number(p.row.weight_) || 0) + (Number(p.row.weight) || 0)) *
                (Number(p.row.price) || 0) -
                (Number(p.row?.weight || 0) + Number(p.row?.weight_ || 0)) *
                  Number(p.row?.price || 0) *
                  (Number(p?.row?.provider?.deduction || 0) / 100) || 0
            )}`
          }}
        </template>
      </el-table-column>
    </el-table>
    <el-row v-show="Animals?.data?.length">
      <el-col :md="3">
        <p class="summary summary-black">Total:</p>
      </el-col>
      <el-col :md="3"> </el-col>
      <el-col :md="3"> </el-col>
      <el-col :md="6"> </el-col>
      <el-col :md="3"> </el-col>
      <el-col :md="3"> </el-col>
      <el-col :md="3">
        <p class="summary summary-black">
          {{
            currencyFormatter.format(
              Animals?.data
                ?.map((a) => sumWeights(a) * (Number(a?.price) || 0))
                ?.reduce((t, e) => (t += e), 0) || 0
            )
          }}
        </p>
      </el-col>
    </el-row>
    <el-pagination
      @update:current-page="currentPage = $event"
      :current-page="currentPage"
      background
      layout="prev, pager, next"
      :page-count="Animals?.lastPage"
    >
    </el-pagination>
    <filter-animals-modal
      :showModal="showFilterAnimalsModal"
      @close-modal="showFilterAnimalsModal = false"
      @update-filters="updateFilters"
    ></filter-animals-modal>
  </el-card>
</template>

<script>
import FilterAnimalsModal from "./modals/FilterAnimalsModal.vue";
import { ElNotification } from "element-plus";

export default {
  name: "AnimalsReportPage",
  components: {
    FilterAnimalsModal,
  },
  data: () => ({
    hasError: false,
    isLoading: false,
    showBillModal: false,
    showFilterAnimalsModal: false,
    animals: [],
    currentPage: 1,
    isLoadingDownloadButton: false,
    showPackingListCheckModal: false,
    showPackingListAnimalsModal: false,
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
    }),
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormatter: new Intl.NumberFormat("pt-BR", {
      style: "decimal",
    }),
    partners: null,
    filterOptions: {},
    showPackingListModal: false,
  }),
  watch: {
    currentPage() {
      this.fetchAnimals();
    },
  },
  mounted() {
    this.fetchAnimals();
    this.fetchPartners();
  },
  computed: {
    Partners() {
      return this.partners || [];
    },
    Animals() {
      return this.animals || [];
    },
  },
  methods: {
    sumWeights(animal) {
      return (Number(animal?.weight) || 0) + (Number(animal?.weight_) || 0);
    },
    changeDownloadButtonStatus() {
      this.isLoadingDownloadButton = !this.isLoadingDownloadButton;
    },
    updateFilters(filters) {
      this.filterOptions = filters || {};
      this.resetPagination();
      this.fetchAnimals();
    },
    resetPagination() {
      this.currentPage = 1;
    },
    openFilterAnimalsModal() {
      this.showFilterAnimalsModal = true;
    },
    downloadReport() {
      this.changeDownloadButtonStatus();

      ElNotification.info({
        title: "Gerando o relatório",
        message: "Isso pode levar alguns segundos",
      });

      const url = new URL(`${this.$store.state.apiUrl}animals/report`);
      url.search = new URLSearchParams({
        ...this.filterOptions,
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "blob",
        },
      })
        .then((response) => {
          if (response.ok) return response.blob();
          else throw response.json();
        })
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Relatório de Animais.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(async (e) => {
          ElNotification.error({
            title: "Ocorreu um erro",
            message: (await e).message,
          });
        })
        .finally(() => this.changeDownloadButtonStatus());
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    fetchPartners() {
      const url = new URL(`${this.$store.state.apiUrl}partners`);
      url.search = new URLSearchParams({ is_provider: true });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.partners = json));
    },
    fetchAnimals() {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}animals`);
      url.search = new URLSearchParams({
        pagination: this.currentPage,
        ...(this.filterOptions || {}),
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => {
          this.animals = json;
        })
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
.summary-black {
  color: black;
}
.summary {
  color: #333;
  font-weight: 700 !important;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", SimSun, sans-serif;
  font-size: 12px;
  text-align: center;
}
.mb-0 {
  margin-bottom: 0px !important;
}
</style>